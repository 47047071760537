<template>
  <div>
    <span
      v-if="info.column.type === 'tag'"
    >
      <span
        v-for="(valor, $index) in valorLista(info, info.column.field)"
        :key="$index"
        class="badge badge-primary ml-50"
      >
        {{ valor }}
      </span>
    </span>
    <span
      v-if="info.column.type === 'dinheiro'"
    >
      {{ valorDinheiro(info.column.field) }}
    </span>
    <span
      v-if="info.column.type === 'dinheiro'"
    >
      {{ valorDinheiro(info.column.field) }}
    </span>
    <span
      v-if="info.column.editable"
      @click.stop="info.column.evento(info.row)"
    >
      {{ info.formattedRow[info.column.field] }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const valorLista = (linha, colunaNome) => {
      const valorLinha = linha.row[colunaNome]
      return valorLinha ? valorLinha.split(';') : []
    }

    const valorDinheiro = (linha, colunaNome) => {
      const formatter = new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        precision: 2,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
      })
      const valor = linha.row[colunaNome]
      return valor ? `R$ ${formatter.format(valor.replace('.', '').replace(',', '.'))}` : valor
    }

    return {
      valorLista,
      valorDinheiro,
    }
  },
})
</script>
